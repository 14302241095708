import SellFormType from "./SellFormType";
import React, {useContext, useState} from "react";
import GeneralSpecs from "./GeneralSpecs";
import RandomSpecs from "./RandomSpecs";
import Address from "./Address";
import Contact from "./Contact";
import axios from "axios";
import {API_ADDRESS, AppContext} from "../../../App";

export const sellFormDefault = {
    type: "",
    subType: "",
    livingArea: 50,
    terrainArea: 400,
    constructionYear: 2000,
    rooms: 2,
    state: "",
    bathrooms: 1,
    garage: false,
    parkingSpot: false,
    city: "",
    postalCode: "",
    address: "",
    title: "",
    lastName: "",
    name: "",
    phone: "",
    email: "",
    motive: ""
};

export const SellFormContext = React.createContext({
    sellForm: sellFormDefault,
    setSellForm: (sellForm) => {
        this.sellForm = sellForm;
    }
});

export function copySellForm(sellForm) {
    return JSON.parse(JSON.stringify(sellForm));
}

function SellForm() {
    const {setToastIsOpen, setToastMessage, setToastType} = useContext(AppContext);

    const [sellForm, setSellForm] = useState(sellFormDefault);
    const value = {sellForm, setSellForm};

    const [step, setStep] = useState(0);

    function handleBack() {
        if (step > 0) {
            setStep(step - 1);
        }
    }

    function handleNext() {
        if (validate()) {
            if (step < 4) {
                setStep(step + 1);
            }
            if (step === 4) {
                axios.post(API_ADDRESS + `/contact/sell`, sellForm)
                    .then(res => {
                        if (res.data.sent) {
                            setToastType("info");
                            setToastMessage("Votre demande a été envoyée!");
                            setToastIsOpen(true);

                            window.location.replace('/vendre?formulaire=envoye');
                        }
                    });
            }
        } else {
            setToastType("warning");
            setToastMessage("Veuillez renseigner tous les champs contenant un astérisque(*).");
            setToastIsOpen(true);
        }
    }

    function validate() {
        let validate = true;
        if (step === 0) {
            validate = sellForm.subType !== sellFormDefault.subType;
        } else if (step === 1) {
            validate = sellForm.state !== sellFormDefault.state;
        } else if (step === 3) {
            validate = sellForm.city !== sellFormDefault.city &&
                sellForm.postalCode !== sellFormDefault.postalCode;
        } else if (step === 4) {
            validate = sellForm.title !== sellFormDefault.title &&
                sellForm.lastName !== sellFormDefault.lastName &&
                sellForm.name !== sellFormDefault.name &&
                sellForm.email !== sellFormDefault.email &&
                sellForm.motive !== sellFormDefault.motive;
        }

        return validate;
    }

    return (
        <div className="container mx-auto w-4/5 h-full">
            <div className="flex flex-col justify-between h-full">
                <div className="py-10 px-4">
                    <SellFormContext.Provider value={value}>
                        <div className={step !== 0 ? "hidden " : ""}>
                            <SellFormType/>
                        </div>
                        <div className={step !== 1 ? "hidden " : ""}>
                            <GeneralSpecs/>
                        </div>
                        <div className={step !== 2 ? "hidden " : ""}>
                            <RandomSpecs/>
                        </div>
                        <div className={step !== 3 ? "hidden " : ""}>
                            <Address/>
                        </div>
                        <div className={step !== 4 ? "hidden " : ""}>
                            <Contact/>
                        </div>
                    </SellFormContext.Provider>
                </div>
                <div className={"flex " + (step === 0 ? "justify-end" : "justify-between")}>
                    <button onClick={handleBack}
                            className={"text-primary px-2 py-1" + (step === 0 ? " hidden" : "")}>Retour
                    </button>
                    <button onClick={handleNext}
                            className="bg-primary hover:bg-primary-light text-white rounded px-2 py-1">{step < 4 ? "Continuer" : "Envoyer"}</button>
                </div>
            </div>
        </div>
    );
}

export default SellForm;
